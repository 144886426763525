import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { registerLicense } from '@syncfusion/ej2-base';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faBars } from '@fortawesome/free-solid-svg-icons'

/* Se añaden los iconos a la librería */
library.add(faBars)

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1jWH9Yc3BQQ2deUkY=');
createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
