<template>
  <div class="center">
    <!-- Logo -->
    <img src="@/assets/logoHome.png" alt="" style="max-width: 250px;" />
  </div>
  <router-view />
  <footer>
    <div class="footer" style="width: 100%;" >
      <img src="@/assets/logoPie.png" alt="" style="max-width: 250px;" />
    </div>    
  </footer>
</template>

<style>
  @import "./styles/appView.css";
</style>

<script>
  // import axios from "axios";
  // import { Buffer } from 'buffer';

  export default {
    data: () => ({
      // endpoint: [],
    }),
    created() {
      // // Se le guardan las credenciales de acceso en ambas variables
      // var username = 'apiconfirma';
      // var password = 'developer';

      // // Se le pasan las credenciales de acceso
      // const token = `${username}:${password}`;
      // const encodedToken = Buffer.from(token).toString('base64');
      // // const preguntaRespuesta_url = 'https://apiconfirma.dev.btponetec.es/public/operaciones/190884';
      // const preguntaRespuesta_url = 'https://apiconfirma.dev.btponetec.es/public/operaciones/191353';

      // // Se realiza la consulta de los datos al endpoint
      // var config = {
      //   method: 'get',
      //   url: preguntaRespuesta_url,
      //   headers: {
      //     "Authorization": "Basic " + encodedToken
      //   }
      // };

      // // Se cargan los datos del endpoint en un array llamado endpoint y se guardan en el LocalStorage
      // axios(config).then(result => {
      //   // this.endpoint = Object(result.data);
      //   window.localStorage.setItem('endpoint', JSON.stringify(Object(result.data)));
      // }).catch(function (error) {
      //   console.log(error);
      // });
    },
  }
</script>