import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'endpoint',
    component:() => import('../views/EndpointView.vue')
  },
  {
    path: '/home/:id',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/landing/:id',
    name: 'landing',
    component: () => import('../views/LandingView.vue')
  },
  {
    path: '/otp/:id',
    name: 'otp',
    component: () => import('../views/OtpView.vue')
  },
  {
    path: '/preguntaRespuesta/:id',
    name: 'preguntaRespuesta',
    component: () => import('../views/PreguntaRespuestaView.vue')
  },
  {
    path: '/landingFinalizada',
    name: 'landingFinalizada',
    component: () => import('../views/LandingFinalizadaView.vue')
  },
  {
    path: '/prueba',
    name: 'prueba',
    component: () => import('../views/PruebaView.vue')
  },
  {
    path: '/prueba2',
    name: 'prueba2',
    component: () => import('../views/Prueba2View.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router